// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form_input_wrapper__o-rdv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-right: 26px;
  margin-bottom: 20px;
}

.styles_form_input_wrapper__o-rdv > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.styles_form_input_wrapper__o-rdv > div > div {
  padding-top: 0 !important;
  overflow: unset;
}

.styles_form_input_wrapper__o-rdv > div > div:nth-child(1) {
  background: white;
}

.styles_date_input__d\\+wTd {
  width: 100%;
}

.styles_image__BiUMg {
  width: 100%;
}
.styles_image__BiUMg > div {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/objection-form/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".form_input_wrapper {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 16px;\n  margin-right: 26px;\n  margin-bottom: 20px;\n}\n\n.form_input_wrapper > div {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.form_input_wrapper > div > div {\n  padding-top: 0 !important;\n  overflow: unset;\n}\n\n.form_input_wrapper > div > div:nth-child(1) {\n  background: white;\n}\n\n.date_input {\n  width: 100%;\n}\n\n.image {\n  width: 100%;\n\n  > div {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_input_wrapper": `styles_form_input_wrapper__o-rdv`,
	"date_input": `styles_date_input__d+wTd`,
	"image": `styles_image__BiUMg`
};
export default ___CSS_LOADER_EXPORT___;
