// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__w1G3e {
  font-size: 25px;
  margin-bottom: 40px;
  color: #1083b7;
}`, "",{"version":3,"sources":["webpack://./src/components/page-title/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".title {\n  font-size: 25px;\n  margin-bottom: 40px;\n  color: #1083b7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__w1G3e`
};
export default ___CSS_LOADER_EXPORT___;
