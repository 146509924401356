// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_active__bdKot {
  color: #3a9d3f;
  margin-right: 40px;
  font-weight: 700;
}

.styles_blocked__B3sde {
  color: red;
  margin-right: 40px;
  font-weight: 700;
}

.styles_th_index__jvnx1 {
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/component/users-table/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AACA;EACI,UAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,WAAA;AAGJ","sourcesContent":[".active{\n    color: #3a9d3f;\n    margin-right: 40px;\n    font-weight: 700;\n}\n.blocked{\n    color: red;\n    margin-right: 40px;\n    font-weight: 700;\n}\n.th_index{\n    width: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `styles_active__bdKot`,
	"blocked": `styles_blocked__B3sde`,
	"th_index": `styles_th_index__jvnx1`
};
export default ___CSS_LOADER_EXPORT___;
