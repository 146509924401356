// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_table_section__o1Zyv {
  grid-column: 1/3;
  padding: 15px;
  margin: 0 20px 20px 0;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 16px !important;
  box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.0392156863) !important;
  cursor: pointer;
}
@media (max-width: 990px) {
  .styles_table_section__o1Zyv {
    grid-column: auto;
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/use-trademark/components/detail-table/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,qBAAA;EACA,iBAAA;EACA,yBAAA;EACA,8BAAA;EACA,mEAAA;EACA,eAAA;AACF;AACE;EAVF;IAWI,iBAAA;IACA,gBAAA;EAEF;AACF","sourcesContent":[".table_section {\n  grid-column: 1/3;\n  padding: 15px;\n  margin: 0 20px 20px 0;\n  background: white;\n  border: 1px solid #e8e8e8;\n  border-radius: 16px !important;\n  box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.0392156863) !important;\n  cursor: pointer;\n\n  @media (max-width: 990px) {\n    grid-column: auto;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_section": `styles_table_section__o1Zyv`
};
export default ___CSS_LOADER_EXPORT___;
