// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__3j4F4 {
  max-width: 100%;
  padding-bottom: 20px;
}
.styles_wrapper__3j4F4 p {
  margin: 0;
}
.styles_wrapper__3j4F4 .styles_title__Lzgrs {
  text-align: center;
}
.styles_wrapper__3j4F4 .styles_form__bFCAi {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto 55px auto;
}
.styles_wrapper__3j4F4 .styles_form__bFCAi .styles_amount__Ibxbw {
  display: flex;
  gap: 10px;
}
.styles_wrapper__3j4F4 .styles_form__bFCAi .styles_submit_btn__bIaCI {
  width: 200px;
  margin: 0 auto;
}
.styles_wrapper__3j4F4 .styles_list__1Hlo9 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}
.styles_wrapper__3j4F4 .styles_list__1Hlo9 .styles_list_item__tthJ1 {
  display: flex;
  justify-content: space-between;
  border: 1px solid gray;
  padding: 6px;
  border-radius: 6px;
}

.styles_filter_submit_btn__siA2K {
  position: relative;
  z-index: 9999;
  height: 39px;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 25px !important;
  transition: all 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/pages/operations/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;AACF;AACE;EACE,SAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,wBAAA;AADJ;AAGI;EACE,aAAA;EACA,SAAA;AADN;AAII;EACE,YAAA;EACA,cAAA;AAFN;AAME;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;AAJJ;AAMI;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AAJN;;AAQA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,oBAAA;AALF","sourcesContent":[".wrapper {\n  max-width: 100%;\n  padding-bottom: 20px;\n\n  p {\n    margin: 0;\n  }\n\n  .title {\n    text-align: center;\n  }\n\n  .form {\n    max-width: 600px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin: 0 auto 55px auto;\n\n    .amount {\n      display: flex;\n      gap: 10px;\n    }\n\n    .submit_btn {\n      width: 200px;\n      margin: 0 auto;\n    }\n  }\n\n  .list {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 90%;\n\n    .list_item {\n      display: flex;\n      justify-content: space-between;\n      border: 1px solid gray;\n      padding: 6px;\n      border-radius: 6px;\n    }\n  }\n}\n.filter_submit_btn {\n  position: relative;\n  z-index: 9999;\n  height: 39px;\n  padding: 6px 8px;\n  border-radius: 4px;\n  margin: 25px !important;\n  transition: all 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__3j4F4`,
	"title": `styles_title__Lzgrs`,
	"form": `styles_form__bFCAi`,
	"amount": `styles_amount__Ibxbw`,
	"submit_btn": `styles_submit_btn__bIaCI`,
	"list": `styles_list__1Hlo9`,
	"list_item": `styles_list_item__tthJ1`,
	"filter_submit_btn": `styles_filter_submit_btn__siA2K`
};
export default ___CSS_LOADER_EXPORT___;
