// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_textarea__Ki7FI {
  padding: 8px;
  resize: none;
  outline: none;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
}
.styles_textarea__Ki7FI:focus-visible {
  border: 1px solid #0065cd;
  box-shadow: #0065cd 0px 1px 2px, #0067cd 0px 0px 0px 1px;
}
.styles_textarea__Ki7FI::placeholder {
  font-size: 16px;
  color: hsl(0, 0%, 50%);
}

.styles_textarea_error__pM56T {
  padding: 20px;
  border: 2px solid #d32f2f;
}
.styles_textarea_error__pM56T:focus-visible {
  border: 4px solid #d32f2f !important;
}

.styles_error__UEN2Y {
  color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/components/textarea/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;EACA,wDAAA;AACJ;AAEE;EACE,eAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;AADF;AAEE;EACE,oCAAA;AAAJ;;AAIA;EACE,cAAA;AADF","sourcesContent":[".textarea {\n  padding: 8px;\n  resize: none;\n  outline: none;\n  border-color: hsl(0, 0%, 80%);\n  border-radius: 4px;\n\n  &:focus-visible {\n    border: 1px solid #0065cd;\n    box-shadow: #0065cd 0px 1px 2px, #0067cd 0px 0px 0px 1px;\n  }\n\n  &::placeholder {\n    font-size: 16px;\n    color: hsl(0, 0%, 50%);\n  }\n}\n\n.textarea_error {\n  padding: 20px;\n  border: 2px solid #d32f2f;\n  &:focus-visible {\n    border: 4px solid #d32f2f !important;\n  }\n}\n\n.error {\n  color: #d32f2f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `styles_textarea__Ki7FI`,
	"textarea_error": `styles_textarea_error__pM56T`,
	"error": `styles_error__UEN2Y`
};
export default ___CSS_LOADER_EXPORT___;
