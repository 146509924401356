// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_upload_file__tu8Dm {
  width: 100%;
  transition: 0.6s;
  height: 70px;
}
.styles_upload_file__tu8Dm:hover {
  opacity: 0.6;
}
.styles_upload_file__tu8Dm:hover label {
  transition: 0.4s;
  transform: scale(1.01);
}
.styles_upload_file__tu8Dm .styles_dropzone__827sB {
  height: inherit;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  height: 40px;
}
.styles_upload_file__tu8Dm .styles_dropzone__827sB input[type=file] {
  display: none;
}
.styles_upload_file__tu8Dm .styles_dropzone__827sB input[type=file]:disabled {
  cursor: not-allowed;
}
.styles_upload_file__tu8Dm .styles_dropzone__827sB label {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  outline: none;
  cursor: pointer;
  transition: 0.4s;
}
.styles_upload_file__tu8Dm .styles_dropzone__827sB img {
  width: 25px;
}
.styles_upload_file__tu8Dm .styles_error__AD1HR {
  color: #d32f2f;
}

.styles_file_list__MTHsm {
  margin-top: 0;
  list-style-type: none;
  padding: 0;
  max-width: 200px;
}
.styles_file_list__MTHsm li {
  display: flex;
  flex-wrap: wrap;
}

.styles_file_link__YbCCB {
  display: inline-block;
  max-width: 200px;
  color: #1976d2;
}`, "",{"version":3,"sources":["webpack://./src/components/uploads/file/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;AACJ;AACI;EACE,gBAAA;EACA,sBAAA;AACN;AAGE;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;AADJ;AAGI;EACE,aAAA;AADN;AAGM;EACE,mBAAA;AADR;AAKI;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AAHN;AAMI;EACE,WAAA;AAJN;AAQE;EACE,cAAA;AANJ;;AAUA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,gBAAA;AAPF;AAQE;EACE,aAAA;EACA,eAAA;AANJ;;AAUA;EACE,qBAAA;EACA,gBAAA;EACA,cAAA;AAPF","sourcesContent":[".upload_file {\r\n  width: 100%;\r\n  transition: 0.6s;\r\n  height: 70px;\r\n\r\n  &:hover {\r\n    opacity: 0.6;\r\n\r\n    label {\r\n      transition: 0.4s;\r\n      transform: scale(1.01);\r\n    }\r\n  }\r\n\r\n  .dropzone {\r\n    height: inherit;\r\n    border: 2px dashed #ccc;\r\n    border-radius: 5px;\r\n    background-color: #f9f9f9;\r\n    height: 40px;\r\n\r\n    input[type=\"file\"] {\r\n      display: none;\r\n\r\n      &:disabled {\r\n        cursor: not-allowed;\r\n      }\r\n    }\r\n\r\n    label {\r\n      height: inherit;\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: center;\r\n      gap: 15px;\r\n      outline: none;\r\n      cursor: pointer;\r\n      transition: 0.4s;\r\n    }\r\n\r\n    img {\r\n      width: 25px;\r\n    }\r\n  }\r\n\r\n  .error {\r\n    color: #d32f2f;\r\n  }\r\n}\r\n\r\n.file_list {\r\n  margin-top: 0;\r\n  list-style-type: none;\r\n  padding: 0;\r\n  max-width: 200px;\r\n  li {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n  }\r\n}\r\n\r\n.file_link {\r\n  display: inline-block;\r\n  max-width: 200px;\r\n  color: #1976d2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_file": `styles_upload_file__tu8Dm`,
	"dropzone": `styles_dropzone__827sB`,
	"error": `styles_error__AD1HR`,
	"file_list": `styles_file_list__MTHsm`,
	"file_link": `styles_file_link__YbCCB`
};
export default ___CSS_LOADER_EXPORT___;
