// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fields_form__RghYA {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.styles_fields_form__RghYA .styles_fields_container__3dT-t {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.styles_fields_form__RghYA .styles_inputs_block__8yKAW,
.styles_fields_form__RghYA .styles_selects_block__T941S {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.styles_fields_form__RghYA .styles_filter_select__w\\+PJl {
  width: 100%;
}
.styles_fields_form__RghYA button {
  margin: 20px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/trademark/components/filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,SAAA;AACJ;AAEE;;EAEE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".fields_form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n\n  .fields_container {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    gap: 20px;\n  }\n\n  .inputs_block,\n  .selects_block {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n\n  .filter_select {\n    width: 100%;\n  }\n\n  button {\n    margin: 20px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields_form": `styles_fields_form__RghYA`,
	"fields_container": `styles_fields_container__3dT-t`,
	"inputs_block": `styles_inputs_block__8yKAW`,
	"selects_block": `styles_selects_block__T941S`,
	"filter_select": `styles_filter_select__w+PJl`
};
export default ___CSS_LOADER_EXPORT___;
