import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GET_USE_TRADEMARK_DETAIL_TABLE_THEAD } from "../../../../utils/get-thead";
import { getTableRows } from "../../../../constant/use-trademark";

import styles from "./styles.module.scss";

const DetailTable = ({ upload_file }) => {
  const rows = getTableRows(upload_file);

  return (
    <Paper
      className={styles.table_section}
      style={{ width: `calc(100% - 30px - 20px)` }}
    >
      <DataGrid
        hideFooter
        rows={rows}
        disableColumnMenu
        localeText={{ noRowsLabel: "Տվյալներ չկան" }}
        columns={GET_USE_TRADEMARK_DETAIL_TABLE_THEAD()}
      />
    </Paper>
  );
};

export default DetailTable;
