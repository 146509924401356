// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_list__kNvAQ {
  display: flex;
  flex-direction: column;
}
.styles_list__kNvAQ .styles_list_item__WT01u {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin: 20px 0;
  width: 90%;
  border: 1px solid #e8e8e8;
  background: white;
  box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.0392156863);
  border-radius: 16px;
  transition: 0.3s;
  cursor: pointer;
  padding: 16px;
  margin: 10px 0;
}
.styles_list__kNvAQ .styles_list_item__WT01u:hover {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.styles_list__kNvAQ .styles_list_item__WT01u p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/doc-type/components/list/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;ECLA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,cAAA;EACA,UAAA;EACA,yBAAA;EACA,iBAAA;EACA,wDAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EDJE,aAAA;EACA,cAAA;AAUJ;ACLE;EACE,0FAAA;ADOJ;AAXI;EACE,SAAA;AAaN","sourcesContent":["@use \"../../../../styles/mixins\";\n\n.list {\n  display: flex;\n  flex-direction: column;\n\n  .list_item {\n    @include mixins.listStyles;\n    padding: 16px;\n    margin: 10px 0;\n\n    p {\n      margin: 0;\n    }\n  }\n}\n","@mixin listStyles {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  gap: 40px;\r\n  margin: 20px 0;\r\n  width: 90%;\r\n  border: 1px solid #e8e8e8;\r\n  background: white;\r\n  box-shadow: 2px 4px 13px 0px #0000000a;\r\n  border-radius: 16px;\r\n  transition: 0.3s;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,\r\n      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;\r\n  }\r\n}\r\n\r\n@mixin flexCenter($justifyContent: flex-start, $gap: 0) {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: $justifyContent;\r\n  gap: $gap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styles_list__kNvAQ`,
	"list_item": `styles_list_item__WT01u`
};
export default ___CSS_LOADER_EXPORT___;
