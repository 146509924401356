// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__BquEx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.styles_add_input__8CQ1q {
  display: flex;
  align-items: center;
}
.styles_dev_inp_box__10yiY {
  width: 200px;
  margin: 10px 0;
}

.styles_delete_icon__sWJA9 {
  color: red;
}

.styles_add_button__4\\+qTF {
  width: 10px;
  height: 30px;
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/add-input/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;AAKA;EACE,YAAA;EACA,cAAA;AAHF;;AAKA;EACE,UAAA;AAFF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AADF","sourcesContent":[".container {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n.add_input {\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  .add_inp_box {\r\n    // width: 200px;\r\n    // margin: 10px 0;\r\n  }\r\n}\r\n.dev_inp_box {\r\n  width: 200px;\r\n  margin: 10px 0;\r\n}\r\n.delete_icon {\r\n  color: red;\r\n}\r\n.add_button {\r\n  width: 10px;\r\n  height: 30px;\r\n  padding: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__BquEx`,
	"add_input": `styles_add_input__8CQ1q`,
	"dev_inp_box": `styles_dev_inp_box__10yiY`,
	"delete_icon": `styles_delete_icon__sWJA9`,
	"add_button": `styles_add_button__4+qTF`
};
export default ___CSS_LOADER_EXPORT___;
