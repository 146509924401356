// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_error__-xQxn {
  margin-top: 5px;
  color: #d32f2f;
}

.styles_options_block__HBBrM {
  display: flex;
  align-items: center;
  padding: 3px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/main-select/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".error {\r\n  margin-top: 5px;\r\n  color: #d32f2f;\r\n}\r\n\r\n.options_block {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 3px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `styles_error__-xQxn`,
	"options_block": `styles_options_block__HBBrM`
};
export default ___CSS_LOADER_EXPORT___;
