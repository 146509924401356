import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GET_PARTNER_DETAILS_TABLE_THEAD } from "../../../../utils/get-thead";
import { CompanyAPI } from "../../../../services/company";
import Back from "../../../../components/back-btn/back-btn";
import { CityAPI } from "../../../../services/city";
import { renderDetailRow } from "../../../../helpers/render-detail-row";
import {
  DETAIL_FIELDS,
  DETAIL_TABLES_ROW_DATA,
} from "../../../../constant/company";

import styles from "../../../objections/styles.module.scss";

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.city);
  const { oneCompany } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(CompanyAPI.getOneCompany(id));
    dispatch(CityAPI.getCities());
  }, [dispatch, id]);

  const { name, image, city_id, upload_file } = oneCompany || {};

  const currentCity = list?.find((city) => city.id === city_id)?.name || "N/A";

  return (
    <div className={styles.service}>
      <Back />
      <h2 className={styles.title}>{name}</h2>
      <div className={styles.details_container}>
        {DETAIL_FIELDS(oneCompany, currentCity).map((section) => (
          <div className={styles.section} key={section.title}>
            <h3>{section.title}</h3>
            {section.fields.map((field) =>
              renderDetailRow(field.label, field.value)
            )}
            {section.title === "Լրացուցիչ տեղեկություններ" && (
              <div className={styles.row}>
                <img
                  width={100}
                  alt="company"
                  className={styles.image}
                  src={
                    image
                      ? `${process.env.REACT_APP_BASE_URL_IMG}${image}`
                      : `${process.env.PUBLIC_URL}/images/no-image.svg`
                  }
                />
              </div>
            )}
          </div>
        ))}
        <Paper elevation={3} sx={{ mb: 2 }} className={styles.table_section}>
          <DataGrid
            hideFooter
            disableColumnMenu
            rows={DETAIL_TABLES_ROW_DATA(upload_file)}
            columns={GET_PARTNER_DETAILS_TABLE_THEAD()}
            localeText={{ noRowsLabel: "Տվյալներ չկան" }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default Detail;
