import { getDisplayValue } from "../utils/get-display-value";

const getSections = (
  id,
  trade_mark_name,
  trade_mark_armenian_name,
  currentCity,
  creator
) => [
  {
    title: "Հիմնական տեղեկություններ",
    fields: [
      { label: "ID:", value: id },
      {
        label: "Ապրանքանիշ:",
        value: `${trade_mark_name} / ${trade_mark_armenian_name}`,
      },
      { label: "Երկիր:", value: currentCity },
      { label: "Ստեղծողը:", value: creator },
    ],
  },
];

const getTableRows = (upload_file) =>
  upload_file?.map((file, index) => ({
    id: index + 1,
    name: getDisplayValue(file?.name, "N/A"),
    file_select_type: getDisplayValue(file?.file_select_type, "N/A"),
    issue_number: getDisplayValue(file?.issue_number, "N/A"),
    deadline: getDisplayValue(file?.deadline, "N/A"),
    description: getDisplayValue(file?.description, "N/A"),
    file_path: getDisplayValue(file?.file?.path, "N/A"),
  })) || [];

export { getSections, getTableRows };
