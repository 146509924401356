// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__cImOR {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.styles_fadeIn__jgpRh {
  opacity: 1;
  transform: scale(1);
  min-width: 400px;
  padding: 32px;
}

.styles_fadeOut__jRzK2 {
  opacity: 0;
  transform: scale(0.9);
}

.styles_modal_children__oQfPc {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.styles_modal_children__oQfPc h2 {
  text-align: center;
  margin: 0 0 20px;
  font-size: 25px;
  color: #1083b7;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;EACA,kDAAA;AACF;;AAEA;EACE,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,UAAA;EACA,qBAAA;AACF;;AAEA;EACE,uDAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA;AACF;AACE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACJ","sourcesContent":[".modalContent {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n  transition: opacity 0.3s ease, transform 0.3s ease;\r\n}\r\n\r\n.fadeIn {\r\n  opacity: 1;\r\n  transform: scale(1);\r\n  min-width: 400px;\r\n  padding: 32px;\r\n}\r\n\r\n.fadeOut {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n}\r\n\r\n.modal_children {\r\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\r\n  font-weight: 500;\r\n  font-size: 1.25rem;\r\n  line-height: 1.6;\r\n  letter-spacing: 0.0075em;\r\n\r\n  h2 {\r\n    text-align: center;\r\n    margin: 0 0 20px;\r\n    font-size: 25px;\r\n    color: #1083b7;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__cImOR`,
	"fadeIn": `styles_fadeIn__jgpRh`,
	"fadeOut": `styles_fadeOut__jRzK2`,
	"modal_children": `styles_modal_children__oQfPc`
};
export default ___CSS_LOADER_EXPORT___;
