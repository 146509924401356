// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_country_form__DJW3M {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}
.styles_country_form__DJW3M .styles_title__i8O6D {
  font-size: 25px;
  color: #1083b7;
  text-align: center;
  margin-bottom: 30px;
}
.styles_country_form__DJW3M .styles_select__0wSKc {
  margin: 20px 0;
}
.styles_country_form__DJW3M .styles_select__0wSKc span {
  margin: 0;
}
.styles_country_form__DJW3M button {
  margin: 40px auto 0 !important;
  display: block !important;
}
.styles_country_form__DJW3M .styles_text__Rc5ax {
  margin-bottom: 20px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/attach-country/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAEI;EACE,SAAA;AAAN;AAIE;EACE,8BAAA;EACA,yBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,cAAA;AAHJ","sourcesContent":[".country_form {\n  padding: 20px;\n  max-width: 600px;\n  margin: auto;\n\n  .title {\n    font-size: 25px;\n    color: #1083b7;\n    text-align: center;\n    margin-bottom: 30px;\n  }\n\n  .select {\n    margin: 20px 0;\n\n    span {\n      margin: 0;\n    }\n  }\n\n  button {\n    margin: 40px auto 0 !important;\n    display: block !important;\n  }\n\n  .text {\n    margin-bottom: 20px;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"country_form": `styles_country_form__DJW3M`,
	"title": `styles_title__i8O6D`,
	"select": `styles_select__0wSKc`,
	"text": `styles_text__Rc5ax`
};
export default ___CSS_LOADER_EXPORT___;
