export const HOME_LIST_DATA = [
  {
    title: "Իմ ընկերությունը",
    description: "Տվյալներ ընկերության մասին",
    img_src: "/icons/company.svg",
    link: "/company",
    alt: "company",
  },
  {
    title: "Այլ տեղեկություններ",
    description: "Տեղակայման վայրեր",
    link: "/info",
    img_src: "/icons/map.svg",
    alt: "information",
  },
  {
    title: "Գործընկերներ",
    description: "Գործընկերների հետ փոխարաբերություն",
    link: "/partner-authorized",
    img_src: "icons/partners.svg",
    alt: "partners",
  },
  {
    title: "Հայտատու անձինք",
    description: "Գործընկերների հետ փոխարաբերություն",
    link: "/partner-applicant",
    img_src: "/icons/applicants.svg",
    alt: "map",
  },
  {
    title: "Ապրանքանիշներ",
    description: "Գրանցաված ապրանքանիշեր",
    link: "/trademark",
    img_src: "/icons/brands.svg",
    alt: "brand",
  },
  {
    title: "Առարկություններ",
    description: "Առարկված ապրանքանիշեր",
    link: "/objections",
    img_src: "/icons/objections.svg",
    alt: "Objections",
  },
  {
    title: "Փաստաթղթեր",
    description: "Առարկված ապրանքանիշեր",
    link: "/ledger-docs",
    img_src: "/icons/documents.svg",
    alt: "Documents",
  },
  {
    title: "Գործառնություններ",
    description: "Ներքին գործառնություններ",
    link: "/operations",
    img_src: "/icons/operations.svg",
    alt: "Operations",
  },
  {
    title: "Ապրանքային նշանի օգտագործում ",
    description: "Ապրանքային նշանի օգտագործման վերաբերյալ",
    link: "/use-trademark",
    img_src: "/icons/attached.svg",
    alt: "use-trademark",
  },
];
