// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_btn__huGhh {
  margin: 20px 0 !important;
}
.styles_btn__huGhh span {
  margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/back-btn/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,sBAAA;AAEJ","sourcesContent":[".btn {\r\n  margin: 20px 0 !important;\r\n  span {\r\n    margin: 0px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `styles_btn__huGhh`
};
export default ___CSS_LOADER_EXPORT___;
