// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_layout__69r6G {
  width: 100%;
  display: flex;
}

.styles_children__YRklb {
  width: calc(100% - 60px);
  margin-left: 60px;
  background: #fafbfd;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,wBAAA;EACA,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".layout {\r\n  width: 100%;\r\n  display: flex;\r\n}\r\n\r\n.children {\r\n  width: calc(100% - 60px);\r\n  margin-left: 60px;\r\n  background: #fafbfd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `styles_layout__69r6G`,
	"children": `styles_children__YRklb`
};
export default ___CSS_LOADER_EXPORT___;
