export const HEADER_DROPDOWN = [
  {
    name: "Անձնական տվյալներ",
    link: "/personal",
  },
  {
    name: "Դուրս գալ",
    link: "/login",
  },
];
