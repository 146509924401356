import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ObjectionsAPI } from "../services/objections";
import { CompanyAPI } from "../services/company";
import { PartnerAPI } from "../services/partner";
import { AuthorizationAPI } from "../services/authorization";

const useFetchObjDetailData = (id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    dispatch(ObjectionsAPI.getObjection(id));
    dispatch(CompanyAPI.getCompanies());
    dispatch(PartnerAPI.getPartnersApplicant({ company_is: "applicant" }));
    dispatch(AuthorizationAPI.getUsersList());
  }, [dispatch, id]);
};

export default useFetchObjDetailData;
