import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { DETAIL_TABLES } from "../../../../constant/trademark";
import { LedgerDocsAPI } from "../../../../services/ledger-docs";
import { OperationsAPI } from "../../../../services/operations";
import { setModalOpen } from "../../../../store/modal/slice";
import { setAttachedCountry as setTrademarkAttachedCountry } from "../../../../store/trademark/slice";
import { setAttachedCountry as setObjectionAttachedCountry } from "../../../../store/objections/slice";
import { TradeMarkAPI } from "../../../../services/trademark";
import { ObjectionsAPI } from "../../../../services/objections";

import styles from "../../styles.module.scss";

const DetailTables = ({ id, attach_countries, hrefId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { list: ledgerDocs } = useSelector((state) => state.ledgerDocs);
  const { list: operations } = useSelector((state) => state.operations);

  const deleteHandlers = {
    ledgerDoc: (id, modalId) => {
      dispatch(LedgerDocsAPI.deleteLedgerDoc(id)).then(() => {
        dispatch(setModalOpen({ modalId, isOpen: false }));
      });
    },
    operation: (id, modalId) => {
      dispatch(OperationsAPI.deleteOperation(id)).then(() => {
        dispatch(setModalOpen({ modalId, isOpen: false }));
      });
    },
    attachCountry: (id, modalId) => {
      if (hrefId === "TM") {
        dispatch(TradeMarkAPI.deleteAttachedCountry(id)).then(() => {
          dispatch(setTrademarkAttachedCountry(id));
          dispatch(setModalOpen({ modalId, isOpen: false }));
        });
      } else if (hrefId === "OB") {
        dispatch(ObjectionsAPI.deleteAttachedCountry(id)).then(() => {
          dispatch(setObjectionAttachedCountry(id));
          dispatch(setModalOpen({ modalId, isOpen: false }));
        });
      }
    },
  };

  const TABLE_DATA = DETAIL_TABLES(
    id,
    navigate,
    deleteHandlers,
    ledgerDocs,
    operations,
    attach_countries,
    hrefId
  );

  useEffect(() => {
    const filterKey =
      hrefId === "TM" ? "trade_mark_filter" : "objection_filter";
    dispatch(LedgerDocsAPI.getLedgerDocs({ [filterKey]: id }));
    dispatch(OperationsAPI.getOperations({ [filterKey]: id }));
  }, [dispatch, id, hrefId]);

  return TABLE_DATA.map((table, idx) => (
    <div key={idx}>
      <h2 className={styles.table_title}>
        <Link to={table.href}>
          <img src={`/icons/${table.icon}`} alt={table.title} />
        </Link>
        {table.title}
      </h2>
      <DataGrid
        hideFooter
        disableColumnMenu
        rows={table.rows}
        columns={table.columns}
        getRowHeight={() => "auto"}
        localeText={{ noRowsLabel: "Տվյալներ չկան" }}
      />
    </div>
  ));
};

export default DetailTables;
