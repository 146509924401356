// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_search__2H87R {
  position: relative;
  width: 100%;
}
.styles_search__2H87R .styles_search_inp__YL9Jh {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
}
.styles_search__2H87R .styles_search_icon__WKDSk {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #1976d2;
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/search/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,sBAAA;EACA,uBAAA;AACJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,6BAAA;AAAJ","sourcesContent":[".search {\r\n  position: relative;\r\n  width: 100%;\r\n\r\n  .search_inp {\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n    background-color: white;\r\n  }\r\n\r\n  .search_icon {\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 10px;\r\n    transform: translateY(-50%);\r\n    color: #1976d2;\r\n    cursor: pointer;\r\n    font-size: 20px;\r\n    background-color: transparent;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `styles_search__2H87R`,
	"search_inp": `styles_search_inp__YL9Jh`,
	"search_icon": `styles_search_icon__WKDSk`
};
export default ___CSS_LOADER_EXPORT___;
