import { getDisplayValue } from "../utils/get-display-value";

export const COMPANY_DATA = [
  { name: "name", placeholder: "Կազմակերպության անվանումը *" },
  { name: "fax", placeholder: "Ֆաքս" },
  { name: "city_id", placeholder: "Երկիր" },
  { name: "avc", placeholder: "ՀՎՀՀ" },
  { name: "address", placeholder: "Հասցե" },
  { name: "tax_type", placeholder: "Հարկի տեսակը" },
  { name: "email", placeholder: "Էլ-փոստ" },
  { name: "account_number", placeholder: "Հաշվեհամար" },
  { name: "website", placeholder: "Վեբ-կայք" },
  { name: "bank", placeholder: "Բանկ" },
  { name: "working_days_hours", placeholder: "Աշխատանքային օրեր և ժամեր" },
];

export const DETAIL_FIELDS = (company, currentCity) => [
  {
    title: "Հիմնական տեղեկություններ",
    fields: [
      { label: "ID:", value: company?.id },
      { label: "Ստեղծողը:", value: company?.creator },
      { label: "Կազմակերպության անվանումը:", value: company?.name },
      { label: "Հասցե:", value: company?.address },
      { label: "Էլ. հասցե:", value: company?.email },
      { label: "Երկիր:", value: currentCity },
      { label: "ՀՎՀՀ:", value: company?.avc },
    ],
  },
  {
    title: "Լրացուցիչ տեղեկություններ",
    fields: [
      { label: "Վեբ-կայք:", value: company?.website },
      { label: "Բանկ:", value: company?.bank },
      { label: "Հաշվեհամար:", value: company?.account_number },
      { label: "Հարկի տեսակը:", value: company?.tax_type },
      { label: "Ֆաքս:", value: company?.fax },
      {
        label: "Աշխատանքային օրեր եւ ժամեր:",
        value: company?.working_days_hours,
      },
      { label: "Հեռախոսահամար:", value: company?.phones?.join(", ") },
    ],
  },
];

export const DETAIL_TABLES_ROW_DATA = (upload_file) =>
  upload_file?.map((file, index) => ({
    id: index + 1,
    name: getDisplayValue(file?.name, "N/A"),
    file_select_type: getDisplayValue(file?.file_select_type, "N/A"),
    issue_number: getDisplayValue(file?.issue_number, "N/A"),
    deadline: getDisplayValue(file?.deadline, "N/A"),
    file_path: getDisplayValue(file?.file?.path, "N/A"),
  })) || [];
