import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Sidebar from "../sidebar/sidebar";
import { setPage } from "../../store/pagination/slice";
import { setFilter as setTrademarkFilter } from "../../store/trademark/slice";
import { setFilter as setObjectionFilter } from "../../store/objections/slice";
import { setImageSlice } from "../../store/image/slice";

import styles from "./styles.module.scss";

const Layout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const basePath = location.pathname.split("/")[1];
    const lastVisitedBasePath = sessionStorage.getItem("lastBasePath");

    if (lastVisitedBasePath && lastVisitedBasePath !== basePath) {
      dispatch(setPage(0));
      dispatch(setTrademarkFilter(null));
      dispatch(setObjectionFilter(null));
      dispatch(setImageSlice(null));
    }

    sessionStorage.setItem("lastBasePath", basePath);
  }, [dispatch, location.pathname]);

  return (
    <div className={styles.layout}>
      <Sidebar />
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Layout;
