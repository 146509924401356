// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__agLp9 {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 55px;
}
.styles_form__agLp9 label {
  overflow: visible;
  line-height: 7px;
}
.styles_form__agLp9 input {
  padding: 6.5px 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/doc-type/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACF;AAAE;EACE,iBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,8BAAA;AAEJ","sourcesContent":[".form {\n  display: flex;\n  align-items: center;\n  gap: 30px;\n  margin-bottom: 55px;\n  label {\n    overflow: visible;\n    line-height: 7px;\n  }\n  input {\n    padding: 6.5px 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__agLp9`
};
export default ___CSS_LOADER_EXPORT___;
