import { useMemo } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Back from "../../../../components/back-btn/back-btn";
import {
  getAdditionalInfo,
  getDetailRows,
} from "../../../../constant/objections";
import { renderDetailRow } from "../../../../helpers/render-detail-row";
import DetailTables from "../../../trademark/components/detail-tables/detail-tables";
import useFetchObjDetailData from "../../../../hooks/useFetchObjectionDetail";

import styles from "../../styles.module.scss";

const ObjectionDetail = () => {
  const { id } = useParams();
  useFetchObjDetailData(id);

  const { applicants } = useSelector((state) => state.partner);
  const { usersList } = useSelector((state) => state.authorization);
  const { oneObjection } = useSelector((state) => state.objections);
  const { list: companyList } = useSelector((state) => state.company);

  const {
    creator,
    registrar,
    trade_mark_name,
    trade_mark_armenian_name,
    image_upload,
    description,
    representatives,
    attach_countries,
  } = oneObjection || {};

  const currentUser =
    usersList?.find((user) => user.id === creator)?.name || "N/A";
  const currentApplicant =
    applicants?.find((applicant) => applicant.id === registrar)?.name || "N/A";

  const currentRepresentatives = useMemo(() => {
    if (!representatives?.length) return "N/A";
    return companyList
      .filter((company) => representatives.includes(company.id))
      .map((company) => company.name)
      .join(", ");
  }, [companyList, representatives]);

  const DETAIL_ROWS = getDetailRows(oneObjection);
  const ADDITIONAL_INFO = getAdditionalInfo({
    currentUser,
    currentApplicant,
    description,
    currentRepresentatives,
  });

  return (
    <div className={styles.service}>
      <Back />
      <h2 className={styles.title}>
        {trade_mark_name} / {trade_mark_armenian_name}
      </h2>

      <div className={styles.details_container}>
        <div className={styles.section}>
          <h3>Տեղեկություններ</h3>
          {DETAIL_ROWS.map((row) => renderDetailRow(row.label, row.value))}
        </div>
        <div className={styles.section}>
          <h3>Լրացուցիչ տեղեկություններ</h3>
          {ADDITIONAL_INFO.map((row) => renderDetailRow(row.label, row.value))}
          <div className={styles.row}>
            <span />
            <span>
              <img
                width={180}
                alt="objection"
                className={styles.image}
                src={
                  image_upload
                    ? `${process.env.REACT_APP_BASE_URL_IMG}${image_upload}`
                    : `${process.env.PUBLIC_URL}/images/no-image.svg`
                }
              />
            </span>
          </div>
        </div>
      </div>

      <DetailTables id={id} hrefId="OB" attach_countries={attach_countries} />
    </div>
  );
};

export default ObjectionDetail;
