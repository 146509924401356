import { getDisplayValue } from "../utils/get-display-value";

export const defaultValues = {
  name: "",
  address: "",
  phones: "",
  fax: "",
  email: "",
  website: "",
  working_days_hours: "",
  avc: "",
  tax_type: "",
  account_number: "",
  bank: "",
  image: "",
  city_id: "",
  upload_file: [
    {
      name: "",
      file_select_type: "",
      issue_number: "",
      deadline: "",
      file: null,
    },
  ],
};

export const FILE_TYPE_OPTIONS = [
  { label: "լիազորագիր", value: "authorized" },
  { label: "պայմանագիր", value: "applicant" },
  { label: "այլ", value: "other" },
];

export const MAIN_DETAILS = (partner, currentCity) => [
  { label: "ID:", value: partner?.id },
  { label: "Կազմակերպության անվանումը:", value: partner?.name },
  { label: "Հասցե:", value: getDisplayValue(partner?.address, "N/A") },
  { label: "Էլ. հասցե:", value: getDisplayValue(partner?.email, "N/A") },
  { label: "Վեբ-կայք:", value: getDisplayValue(partner?.website, "N/A") },
  { label: "Երկիր:", value: getDisplayValue(currentCity, "N/A") },
  { label: "ՀՎՀՀ:", value: getDisplayValue(partner?.avc, "N/A") },
];

export const ADDITIONAL_DETAILS = (partner) => [
  { label: "Բանկ:", value: getDisplayValue(partner?.bank, "N/A") },
  {
    label: "Հաշվեհամար:",
    value: getDisplayValue(partner?.account_number, "N/A"),
  },
  { label: "Հարկի տեսակը:", value: getDisplayValue(partner?.tax_type, "N/A") },
  { label: "Ֆաքս:", value: getDisplayValue(partner?.fax, "N/A") },
  {
    label: "Աշխատանքային օրեր եւ ժամեր:",
    value: getDisplayValue(partner?.working_days_hours, "N/A"),
  },
  {
    label: "Հեռախոսահամար:",
    value: getDisplayValue(partner?.phones?.join(", "), "N/A"),
  },
];

export const TABLE_ROWS = (upload_file) =>
  upload_file?.map((file, index) => ({
    id: index + 1,
    name: getDisplayValue(file?.name, "N/A"),
    file_select_type: getDisplayValue(file?.file_select_type, "N/A"),
    issue_number: getDisplayValue(file?.issue_number, "N/A"),
    deadline: getDisplayValue(file?.deadline, "N/A"),
    file_path: getDisplayValue(file?.file?.path, "N/A"),
  })) || [];
