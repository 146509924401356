// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__CafQ6 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.styles_container__CafQ6 .styles_title__s-6fc {
  text-align: center;
  margin-bottom: 30px;
}
.styles_container__CafQ6 .styles_form_input__TnZIW,
.styles_container__CafQ6 .styles_select__-\\+Cig,
.styles_container__CafQ6 .styles_personal_img__HpKsv {
  width: 100%;
  margin-bottom: 20px;
}
.styles_container__CafQ6 .styles_btn_create__xrHEp {
  display: flex;
  margin: 20px auto 0;
}
.styles_container__CafQ6 .styles_form_input__TnZIW {
  position: relative;
  z-index: 0;
}
.styles_container__CafQ6 .styles_form_input__TnZIW div:nth-child(1) {
  width: 100%;
}
.styles_container__CafQ6 .styles_role_text__K8wu2,
.styles_container__CafQ6 .styles_company_text__7YWj2 {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/component/personal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ;AAEE;;;EAGE,WAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,kBAAA;EACA,UAAA;AAFJ;AAKM;EACE,WAAA;AAHR;AAQE;;EAEE,mBAAA;AANJ","sourcesContent":[".container {\n  width: 100%;\n  max-width: 600px;\n  margin: 0 auto;\n\n  .title {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n\n  .form_input,\n  .select,\n  .personal_img {\n    width: 100%;\n    margin-bottom: 20px;\n  }\n\n  .btn_create {\n    display: flex;\n    margin: 20px auto 0;\n  }\n\n  .form_input {\n    position: relative;\n    z-index: 0;\n\n    div {\n      &:nth-child(1) {\n        width: 100%;\n      }\n    }\n  }\n\n  .role_text,\n  .company_text {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__CafQ6`,
	"title": `styles_title__s-6fc`,
	"form_input": `styles_form_input__TnZIW`,
	"select": `styles_select__-+Cig`,
	"personal_img": `styles_personal_img__HpKsv`,
	"btn_create": `styles_btn_create__xrHEp`,
	"role_text": `styles_role_text__K8wu2`,
	"company_text": `styles_company_text__7YWj2`
};
export default ___CSS_LOADER_EXPORT___;
