import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ObjectionsAPI } from "../../services/objections";
import { setModalOpen } from "../../store/modal/slice";
import { setObjectionId } from "../../store/objections/slice";
import Params from "../../helpers/params";
import useActionHandlers from "../../hooks/useActionHandlers";
import Search from "../../components/search/search";
import BasicModal from "../../components/modal/modal";
import PageTitle from "../../components/page-title/page-title";
import DownloadFile from "./components/download-file/download-file";
import Pagination from "../../components/pagination/pagination";
import CreateBtn from "../../components/create-btn/create-btn";
import List from "./components/list/list";
import Filter from "./components/filter/filter";
import { setImageSlice } from "../../store/image/slice";

import styles from "./styles.module.scss";

const Objections = () => {
  const dispatch = useDispatch();

  const { offset } = useSelector((state) => state.pagination);
  const { total, filter } = useSelector((state) => state.objections);

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      search: "",
    },
  });

  const ACTION_HANDLERS = useActionHandlers("OB", ObjectionsAPI, {
    setDeletedEntityId: setObjectionId,
    setModalOpen,
    deleteEntity: "deleteObjection",
  });

  const handleSearch = handleSubmit(async (data) => {
    try {
      const params = { ...Params(), ...data };
      await dispatch(ObjectionsAPI.getObjections(params)).unwrap();
    } catch (error) {
      console.error(error.message);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const searchValue = getValues("search");

        if (filter) {
          const params = {
            ...filter,
            ...Params(20, offset * 20),
          };
          if (searchValue) params.search = searchValue;

          await dispatch(ObjectionsAPI.getObjections(params)).unwrap();
        } else {
          const params = {
            ...Params(20, offset * 20),
          };
          if (searchValue) params.search = searchValue;

          await dispatch(ObjectionsAPI.getObjections(params)).unwrap();
        }
      } catch (error) {
        console.error(error.message);
      }
    })();
    dispatch(setImageSlice(null));
  }, [offset, filter, dispatch, getValues]);

  return (
    <>
      <PageTitle title="Առարկություններ" />
      <div className={styles.top_bar}>
        <CreateBtn
          className={styles.btn_create}
          text="Գրանցել նոր առարկություն"
          onClick={ACTION_HANDLERS.create}
        />
        <Search
          name="search"
          control={control}
          onSearch={handleSearch}
          className={styles.search}
        />
        <div>
          <div>
            <BasicModal
              modalId="thirdModal"
              title="Ֆիլտր"
              variant="outlined"
              className={styles.filter_modal}
            >
              <Filter offset={offset} modalId="thirdModal" />
            </BasicModal>
          </div>
        </div>
        <div className={styles.modal}>
          <BasicModal
            title="Արտածել PDF"
            variant="outlined"
            modalId="secondModal"
            startIcon={
              <img
                width={20}
                height={20}
                alt="pdf icon"
                src={`${process.env.PUBLIC_URL}/icons/pdf.svg`}
              />
            }
          >
            <DownloadFile modalId="secondModal" offset={offset} />
          </BasicModal>
        </div>
      </div>
      <List actions={ACTION_HANDLERS} />
      <Pagination offset={offset} total={total} />
    </>
  );
};

export default Objections;
