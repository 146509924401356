/**
 * Custom hook to derive trademark details.
 *
 * @param {Object} oneTradeMark - The trademark object containing details such as types and representatives.
 * @param {Array} authorizes - List of authorized representatives with their details.
 * @returns {Object} An object containing:
 *  - trademarkTypes: A string representation of the trademark types, or "N/A" if not available.
 *  - currentRepresentatives: A string of current representative names, or "N/A" if not available.
 */

import { useMemo } from "react";
import { MARK_TYPE } from "../constant/trademark";

const useTrademarkDetails = (oneTradeMark, authorizes) => {
  const trademarkTypes = useMemo(
    () =>
      oneTradeMark?.types?.length
        ? MARK_TYPE.filter((type) => oneTradeMark.types.includes(type.value))
            .map((type) => type.label)
            .join(", ")
        : "N/A",
    [oneTradeMark]
  );

  const currentRepresentatives = useMemo(
    () =>
      oneTradeMark?.representatives?.length
        ? authorizes
            .filter((auth) => oneTradeMark.representatives.includes(auth.id))
            .map((auth) => auth.name)
            .join(", ")
        : "N/A",
    [oneTradeMark, authorizes]
  );

  return { trademarkTypes, currentRepresentatives };
};

export default useTrademarkDetails;
