// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main_input__rL4FI input[type=number]::-webkit-inner-spin-button,
.styles_main_input__rL4FI input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.styles_error__i0MQ2 {
  color: #d32f2f;
  position: absolute;
}

.styles_main_input__rL4FI {
  width: 100% !important;
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/components/text-input/styles.module.scss"],"names":[],"mappings":"AAAA;;EAEE,wBAAA;EACA,0BAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,iBAAA;AACF","sourcesContent":[".main_input input[type=\"number\"]::-webkit-inner-spin-button,\r\n.main_input input[type=\"number\"]::-webkit-outer-spin-button {\r\n  -webkit-appearance: none;\r\n  -moz-appearance: textfield;\r\n}\r\n\r\n.error {\r\n  color: #d32f2f;\r\n  position: absolute;\r\n}\r\n\r\n.main_input {\r\n  width: 100% !important;\r\n  background: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_input": `styles_main_input__rL4FI`,
	"error": `styles_error__i0MQ2`
};
export default ___CSS_LOADER_EXPORT___;
