import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../../components/back-btn/back-btn";
import { renderDetailRow } from "../../../../helpers/render-detail-row";
import { UseTrademarkAPI } from "../../../../services/use-trademark";
import { CityAPI } from "../../../../services/city";
import { OperationsAPI } from "../../../../services/operations";
import { getSections } from "../../../../constant/use-trademark";
import DetailTable from "../detail-table/detail-table";

import styles from "../../../objections/styles.module.scss";

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.city);
  const { oneUseTrademark } = useSelector((state) => state.useTrademark);
  const { trademarkObjectionList } = useSelector((state) => state.operations);

  const { creator, country, tm_or_ob, upload_file } = oneUseTrademark || {};

  const currentCity = list?.find((city) => city.id === country)?.name || "N/A";
  const { image, trade_mark_name, trade_mark_armenian_name } =
    trademarkObjectionList.find((item) => item.id === tm_or_ob) || {};

  const sections = getSections(
    id,
    trade_mark_name,
    trade_mark_armenian_name,
    currentCity,
    creator
  );

  useEffect(() => {
    dispatch(CityAPI.getCities());
    dispatch(UseTrademarkAPI.getOne(id));
    dispatch(OperationsAPI.getTrademarkObjectionList());
  }, [dispatch, id]);

  return (
    <div className={styles.service}>
      <Back />
      <h2 className={styles.title}>
        {trade_mark_name} / {trade_mark_armenian_name}
      </h2>
      <div className={styles.details_container}>
        {sections.map((section) => (
          <div className={styles.section} key={section.title}>
            <h3>{section.title}</h3>
            {section.fields.map((field) =>
              renderDetailRow(field.label, field.value)
            )}
            <div className={styles.row}>
              <img
                width={100}
                alt={tm_or_ob}
                className={styles.image}
                src={
                  image
                    ? `${process.env.REACT_APP_BASE_URL_IMG}${image}`
                    : `${process.env.PUBLIC_URL}/images/no-image.svg`
                }
              />
            </div>
            <div className={styles.row}>
              <img
                width={150}
                alt={tm_or_ob}
                className={styles.image}
                src={
                  oneUseTrademark.image
                    ? `${process.env.REACT_APP_BASE_URL_IMG}${oneUseTrademark.image}`
                    : `${process.env.PUBLIC_URL}/images/no-image.svg`
                }
              />
              <span>Ներբեռնված նկար:</span>
            </div>
          </div>
        ))}
        <DetailTable upload_file={upload_file} />
      </div>
    </div>
  );
};

export default Detail;
