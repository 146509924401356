import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Back from "../../../../components/back-btn/back-btn";
import { CityAPI } from "../../../../services/city";
import { PartnerAPI } from "../../../../services/partner";
import { GET_PARTNER_DETAILS_TABLE_THEAD } from "../../../../utils/get-thead";
import { renderDetailRow } from "../../../../helpers/render-detail-row";
import {
  MAIN_DETAILS,
  ADDITIONAL_DETAILS,
  TABLE_ROWS,
} from "../../../../constant/partner";

import styles from "../../../objections/styles.module.scss";

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.city);
  const { onePartner } = useSelector((state) => state.partner);
  const { name, city_id, image, upload_file } = onePartner || {};
  const currentCity = list?.find((city) => city.id === city_id)?.name || "N/A";

  useEffect(() => {
    dispatch(PartnerAPI.getOnePartner(id));
    dispatch(CityAPI.getCities());
  }, [dispatch, id]);

  return (
    <div className={styles.service}>
      <Back />

      <h1 className={styles.title}>{name}</h1>
      <div className={styles.details_container}>
        <div className={styles.section}>
          <h3>Հիմնական տեղեկություններ</h3>
          {MAIN_DETAILS(onePartner, currentCity).map((detail) =>
            renderDetailRow(detail.label, detail.value)
          )}
        </div>

        <div className={styles.section}>
          <h3>Լրացուցիչ տեղեկություններ</h3>
          {ADDITIONAL_DETAILS(onePartner).map((detail) =>
            renderDetailRow(detail.label, detail.value)
          )}
          <div className={styles.row}>
            <span>
              <img
                width={100}
                alt="partner"
                className={styles.image}
                src={
                  image
                    ? `${process.env.REACT_APP_BASE_URL_IMG}${image}`
                    : `${process.env.PUBLIC_URL}/images/no-image.svg`
                }
              />
            </span>
          </div>
        </div>

        <Paper elevation={3} sx={{ mb: 2 }} className={styles.table_section}>
          <DataGrid
            hideFooter
            disableColumnMenu
            rows={TABLE_ROWS(upload_file)}
            columns={GET_PARTNER_DETAILS_TABLE_THEAD()}
            localeText={{ noRowsLabel: "Տվյալներ չկան" }}
          />
        </Paper>
      </div>
    </div>
  );
};
export default Detail;
