// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pagination_btn__ntCSD {
  font-size: 14px !important;
  min-width: 64px;
}
.styles_pagination_btn__ntCSD.styles_active__VEoGp {
  font-size: 16px !important;
  color: #1976d2;
  background: rgba(25, 118, 210, 0.2);
  min-width: 64px;
  height: 31px;
}

.styles_totalPage_btn__et\\+ww {
  font-size: 14px !important;
}

button:first-child, button:last-child {
  font-size: 16px;
}

span {
  margin-bottom: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;AACF;AAAE;EACE,0BAAA;EACA,cAAA;EACA,mCAAA;EACA,eAAA;EACA,YAAA;AAEJ;;AAEA;EACE,0BAAA;AACF;;AAGE;EAEE,eAAA;AADJ;;AAKA;EACE,kBAAA;AAFF","sourcesContent":[".pagination_btn {\n  font-size: 14px !important;\n  min-width: 64px;\n  &.active {\n    font-size: 16px !important;\n    color: #1976d2;\n    background: rgba(25, 118, 210, 0.2);\n    min-width: 64px;\n    height: 31px;\n  }\n}\n\n.totalPage_btn {\n  font-size: 14px !important;\n}\n\nbutton {\n  &:first-child,\n  &:last-child {\n    font-size: 16px;\n  }\n}\n\nspan {\n  margin-bottom: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination_btn": `styles_pagination_btn__ntCSD`,
	"active": `styles_active__VEoGp`,
	"totalPage_btn": `styles_totalPage_btn__et+ww`
};
export default ___CSS_LOADER_EXPORT___;
