import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TradeMarkAPI } from "../../../../services/trademark";
import { PartnerAPI } from "../../../../services/partner";
import { ADDITIONAL_INFO, DETAIL_FIELDS } from "../../../../constant/trademark";
import Back from "../../../../components/back-btn/back-btn";
import useTrademarkDetails from "../../../../hooks/useTrademarkDetails";
import DetailTables from "../detail-tables/detail-tables";
import {
  setClearAttachObjection,
  setClearAttachTradeMark,
} from "../../../../store/attach-country/slice";
import { renderDetailRow } from "../../../../helpers/render-detail-row";

import styles from "../../styles.module.scss";

const TrademarkDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { authorizes } = useSelector((state) => state.partner);
  const { oneTradeMark } = useSelector((state) => state.tradeMark);

  const { trademarkTypes, currentRepresentatives } = useTrademarkDetails(
    oneTradeMark,
    authorizes
  );

  useEffect(() => {
    dispatch(PartnerAPI.getPartnersAuthorized({ company_is: "authorized" }));
    dispatch(TradeMarkAPI.getOneTradeMark(id));
    dispatch(setClearAttachTradeMark());
    dispatch(setClearAttachObjection());
  }, [dispatch, id]);

  return (
    <div className={styles.service}>
      <Back />
      <h1 className={styles.title}>
        {oneTradeMark?.trade_mark_name} \{" "}
        {oneTradeMark?.trade_mark_armenian_name}
      </h1>

      <section className={styles.details_container}>
        {DETAIL_FIELDS(oneTradeMark || []).map((section, idx) => (
          <div key={idx} className={styles.section}>
            <h3>{section.title}</h3>
            {section.data.map(([label, value]) =>
              renderDetailRow(label, value)
            )}
          </div>
        ))}
        <div className={styles.section}>
          <h3>Լրացուցիչ տեղեկություններ</h3>
          {ADDITIONAL_INFO(currentRepresentatives, trademarkTypes).map(
            ({ label, value }) => (
              <div key={label} className={styles.row}>
                <span>{label}</span>
                <span>
                  {typeof value === "function" ? value(oneTradeMark) : value}
                </span>
              </div>
            )
          )}
          <div className={styles.row}>
            <span>
              <img
                width={180}
                alt="objection"
                className={styles.image}
                src={
                  oneTradeMark?.image_path
                    ? `${process.env.REACT_APP_BASE_URL_IMG}${oneTradeMark.image_path}`
                    : `${process.env.PUBLIC_URL}/images/no-image.svg`
                }
              />
            </span>
          </div>
        </div>
      </section>

      <DetailTables
        id={id}
        hrefId="TM"
        attach_countries={oneTradeMark?.attach_countries}
      />
    </div>
  );
};

export default TrademarkDetail;
