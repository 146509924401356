// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_head_item__Y\\+MiB {
  display: flex;
  margin-bottom: 30px;
}
.styles_head_item__Y\\+MiB .styles_search__rGjIK {
  width: 500px;
  margin-left: 20px;
}
.styles_head_item__Y\\+MiB .styles_search__rGjIK .styles_search_inp__CaWg9 {
  padding-right: 0 !important;
  margin-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/operations/components/list/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,iBAAA;AACJ;AAAI;EACE,2BAAA;EACA,yBAAA;AAEN","sourcesContent":[".head_item {\n  display: flex;\n  margin-bottom: 30px;\n\n  .search {\n    width: 500px;\n    margin-left: 20px;\n    .search_inp {\n      padding-right: 0 !important;\n      margin-left: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head_item": `styles_head_item__Y+MiB`,
	"search": `styles_search__rGjIK`,
	"search_inp": `styles_search_inp__CaWg9`
};
export default ___CSS_LOADER_EXPORT___;
