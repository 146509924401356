// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_list_title__ka99y {
  font-size: 25px;
  margin-bottom: 55px;
  color: #1083b7;
}

.styles_list__lPWGH {
  gap: 40px;
  margin: 20px 0;
  width: 90%;
  border: 1px solid #e8e8e8;
  background: white;
  box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.0392156863);
  border-radius: 16px;
  padding: 25px;
  transition: 0.3s;
  cursor: pointer;
}
.styles_list__lPWGH:hover {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.styles_list__lPWGH .styles_info__-n1yL {
  display: flex;
  align-items: center;
}
.styles_list__lPWGH .styles_info__-n1yL .styles_inner_block__HaUd5 {
  display: flex;
  gap: 5%;
  width: 60%;
}
.styles_list__lPWGH .styles_info__-n1yL img {
  margin-left: 5px;
}
.styles_list__lPWGH .styles_info__-n1yL .styles_count__tIGsP {
  margin-left: auto !important;
}

.styles_search__334PN {
  width: 500px;
  margin-left: 20px;
}
.styles_search__334PN .styles_search_inp__qKTsh {
  padding-right: 0 !important;
  margin-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/info/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AADF;;AAIA;EACE,SAAA;EACA,cAAA;EACA,UAAA;EACA,yBAAA;EACA,iBAAA;EACA,wDAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;AADF;AAGE;EACE,0FAAA;AADJ;AAKE;EACE,aAAA;EACA,mBAAA;AAHJ;AAKI;EACE,aAAA;EACA,OAAA;EACA,UAAA;AAHN;AAMI;EACE,gBAAA;AAJN;AAOI;EACE,4BAAA;AALN;;AASA;EACE,YAAA;EACA,iBAAA;AANF;AAOE;EACE,2BAAA;EACA,yBAAA;AALJ","sourcesContent":["@use \"../../styles/mixins.scss\";\n\n.list_title {\n  font-size: 25px;\n  margin-bottom: 55px;\n  color: #1083b7;\n}\n\n.list {\n  gap: 40px;\n  margin: 20px 0;\n  width: 90%;\n  border: 1px solid #e8e8e8;\n  background: white;\n  box-shadow: 2px 4px 13px 0px #0000000a;\n  border-radius: 16px;\n  padding: 25px;\n  transition: 0.3s;\n  cursor: pointer;\n\n  &:hover {\n    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,\n      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;\n  }\n\n  .info {\n    display: flex;\n    align-items: center;\n\n    .inner_block {\n      display: flex;\n      gap: 5%;\n      width: 60%;\n    }\n\n    img {\n      margin-left: 5px;\n    }\n\n    .count {\n      margin-left: auto !important;\n    }\n  }\n}\n.search {\n  width: 500px;\n  margin-left: 20px;\n  .search_inp {\n    padding-right: 0 !important;\n    margin-left: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list_title": `styles_list_title__ka99y`,
	"list": `styles_list__lPWGH`,
	"info": `styles_info__-n1yL`,
	"inner_block": `styles_inner_block__HaUd5`,
	"count": `styles_count__tIGsP`,
	"search": `styles_search__334PN`,
	"search_inp": `styles_search_inp__qKTsh`
};
export default ___CSS_LOADER_EXPORT___;
