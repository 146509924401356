// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__5y6Ji {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.styles_container__5y6Ji .styles_title__ToMrP {
  text-align: center;
}
.styles_container__5y6Ji .styles_form_input__14ueJ,
.styles_container__5y6Ji .styles_select__bCVM-,
.styles_container__5y6Ji .styles_personal_img__0nJP- {
  width: 100%;
  margin-bottom: 20px;
}
.styles_container__5y6Ji .styles_btn_create__1xLdA {
  display: flex;
  margin: 20px auto 0;
}
.styles_container__5y6Ji .styles_form_input__14ueJ {
  position: relative;
  z-index: 0;
}
.styles_container__5y6Ji .styles_form_input__14ueJ div:nth-child(1) {
  width: 100%;
}
.styles_container__5y6Ji .styles_partners__FNAGL,
.styles_container__5y6Ji .styles_role__iZ5My {
  margin-bottom: 10px;
}
.styles_container__5y6Ji .styles_checkBox_block__L-rGW {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.styles_container__5y6Ji .styles_checkBox_block__L-rGW input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/component/edit-user/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;;;EAGE,WAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,kBAAA;EACA,UAAA;AAFJ;AAKM;EACE,WAAA;AAHR;AAQE;;EAEE,mBAAA;AANJ;AASE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AAPJ;AAQI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AANN","sourcesContent":[".container {\r\n  width: 100%;\r\n  max-width: 600px;\r\n  margin: 0 auto;\r\n\r\n  .title {\r\n    text-align: center;\r\n  }\r\n\r\n  .form_input,\r\n  .select,\r\n  .personal_img {\r\n    width: 100%;\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .btn_create {\r\n    display: flex;\r\n    margin: 20px auto 0;\r\n  }\r\n\r\n  .form_input {\r\n    position: relative;\r\n    z-index: 0;\r\n\r\n    div {\r\n      &:nth-child(1) {\r\n        width: 100%;\r\n      }\r\n    }\r\n  }\r\n\r\n  .partners,\r\n  .role {\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .checkBox_block {\r\n    margin-top: 30px;\r\n    display: flex;\r\n    align-items: center;\r\n    input {\r\n      margin-right: 10px;\r\n      width: 20px;\r\n      height: 20px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__5y6Ji`,
	"title": `styles_title__ToMrP`,
	"form_input": `styles_form_input__14ueJ`,
	"select": `styles_select__bCVM-`,
	"personal_img": `styles_personal_img__0nJP-`,
	"btn_create": `styles_btn_create__1xLdA`,
	"partners": `styles_partners__FNAGL`,
	"role": `styles_role__iZ5My`,
	"checkBox_block": `styles_checkBox_block__L-rGW`
};
export default ___CSS_LOADER_EXPORT___;
