// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_submit_btn__S1CMC {
  position: relative;
  z-index: 9999;
  height: 39px;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 25px !important;
  transition: all 0.3s;
}
.styles_submit_btn__S1CMC:hover {
  background: rgba(25, 118, 210, 0.04);
}`, "",{"version":3,"sources":["webpack://./src/pages/ledger-docs/components/filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,oBAAA;AACF;AAAE;EACE,oCAAA;AAEJ","sourcesContent":[".submit_btn {\n  position: relative;\n  z-index: 9999;\n  height: 39px;\n  padding: 6px 8px;\n  border-radius: 4px;\n  margin: 25px !important;\n  transition: all 0.3s;\n  &:hover {\n    background: rgba(25, 118, 210, 0.04);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_btn": `styles_submit_btn__S1CMC`
};
export default ___CSS_LOADER_EXPORT___;
